@import url("https://rsms.me/inter/inter.css");

html {
  font-family: "Inter", sans-serif;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
body {
  padding: 0;
  margin: 0;
  color: #464646;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", sans-serif;
  }
}
